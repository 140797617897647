<template>
  <v-main align="center">
    <v-container class="fill-height" fluid v-if="hasCompany && !status.loading">
      <v-row align="center" justify="center">
        <v-col cols="12" sm="10" md="10">
          <v-card class="elevation-12">
            <CompanyToolbar
              :title="userCompany.name"
              :operations="operations"
            />
            <router-view></router-view>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import { mapActions, mapState } from "vuex";

import CompanyToolbar from "@/components/company/CompanyToolbar";

export default {
  name: "Company",
  data() {
    return {
      name: "",
      orgNumber: "",
      operations: [
        { title: this.$t("common.edit"), to: "/company/edit" },
        { title: this.$t("common.delete"), to: "/company/delete" },
      ],
    };
  },
  computed: {
    ...mapState("users", [
      "status",
      "currentUser",
      "userCompany",
      "hasCompany",
    ]),
  },
  methods: {
    ...mapActions("users", ["getUserCompany"]),
  },
  created() {
    if (!this.hasCompany) this.$router.push("/company/create");
  },
  components: {
    CompanyToolbar,
  },
};
</script>

<style>
</style>